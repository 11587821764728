// styly
import { useRef, useEffect } from "react";

import { trans, isLocalDev } from "~/utils/utils";
import { getSuggestItem } from "~/map/suggestItem";
import { positionStore } from "~/stores/position";
import { setCenterZoom, setPOI, setTiles } from "~/stores/map";
import { appStore } from "~/stores/app";
import { ISuggestData } from "~/interfaces";
import { TILE_REGEX, WGS84_RANGE } from "~/const";
import { getPosFromName } from "~/utils/tile";

import "./style.less";

interface ISearchForm {
	hidden: boolean;
	onSuggest: (suggestData: ISuggestData) => void;
}

export default function SearchForm({
	hidden = false,
	onSuggest = () => {},
}: ISearchForm) {
	const { setSearchQuery } = appStore(appState => ({
		setSearchQuery: appState.setSearchQuery,
	}));
	const { SMap } = window;
	const inputRef = useRef<HTMLInputElement>(null);
	const suggestRef = useRef<any>(null);
	const hideRef = useRef<HTMLSpanElement>(null);

	function clear() {
		suggestRef.current.clearItems();
		inputRef.current.value = "";
		inputRef.current.focus();
		hideRef.current.classList.add("hide");
		setPOI("", 0);
		setSearchQuery("");
	}

	function setQuery(query: string) {
		if (query) {
			let useQuery = true;

			if (query.match(TILE_REGEX)) {
				const position = getPosFromName(query);

				if (position) {
					setCenterZoom(position.mercX, position.mercY, position.zoom);
					setTiles([query]);
					clear();
					useQuery = false;
				}
			}

			useQuery && setSearchQuery(query);
		} else {
			setSearchQuery("");
		}
	}

	function formSubmit(event: any) {
		event.stopPropagation();
		event.preventDefault();

		if (inputRef.current.value) {
			suggestRef.current.clearItems();
			setQuery(inputRef.current.value);
		}
	}

	useEffect(() => {
		if (inputRef.current && !suggestRef.current) {
			// suggest
			suggestRef.current = new SMap.Suggest(inputRef.current, {
				provider: new SMap.SuggestProvider({
					...isLocalDev() ? {} : { url: "/suggest/" },
					poiaggUrl: "/poiagg",
					updateParams: params => {
						const appState = appStore.getState().app;
						const posState = positionStore.getState().position;

						params.lon = posState.x.toFixed(WGS84_RANGE);
						params.lat = posState.y.toFixed(WGS84_RANGE);
						params.zoom = posState.z;
						params.lang = appState.lang;
					},
				}),
				factory: (data, pos) => {
					const SuggestItem = getSuggestItem();

					return new SuggestItem(data, pos);
				},
			});

			suggestRef.current.addListener("enter", () => {
				suggestRef.current.clearItems();
				setQuery(inputRef.current.value);
			});
			suggestRef.current.addListener("suggest", suggestData => {
				hideRef.current.classList.remove("hide");
				onSuggest(suggestData);
			});
			inputRef.current.addEventListener("input", () => {
				if (inputRef.current.value) {
					hideRef.current.classList.remove("hide");
				} else {
					hideRef.current.classList.add("hide");
				}
			});
		}
	}, [inputRef.current]);

	return <form className="search-form" onSubmit={formSubmit} style={{ visibility: hidden ? "hidden" : "visible" }}>
		<label htmlFor="q" className="for-blind">{ trans("search") }</label>
		<input type="text" id="q" autoComplete="off" aria-autocomplete="list" defaultValue="" ref={inputRef} />
		<input type="submit" className="search" value="L" title={ trans("search") } />
		<span className="clear-search hide" onClick={clear} ref={hideRef}></span>
	</form>;
}
