import { HAPTIC_TILE_ZOOMS, VISIBLE_CONTENT, USER_FORM_STATES, WGS84_RANGE, ALL_LANGS, CS_LANG, EN_LANG } from "~/const";
import { IApp } from "~/stores/app";
import { IPosition, ISplitItem } from "~/interfaces";
import { getCookie } from "~/utils/cookies";

export function isLocalDev() {
	return location.href.indexOf("localhost:4444") !== -1 || location.href.indexOf("127.0.0.1") !== -1;
}

export function loadScript(src: string) {
	return new Promise((resolve, reject) => {
		const script = document.createElement("script");

		script.type = "text/javascript";
		script.async = true;
		script.addEventListener("load", () => {
			resolve({});
		});
		script.addEventListener("error", () => {
			reject({});
		});

		script.src = src;
		document.head.appendChild(script);
	});
}

/* eslint-disable no-magic-numbers */
export function timeToSeconds(value: string): number {
	const parts = value.split(":");

	if (parts.length === 2) {
		return (parseFloat(parts[0]) * 60) + parseFloat(parts[1]);
	}

	return 0;
}

export function getRandomHexHash(): string {
	return Math.random().toString(16);
}

export function getYearMonthDesc(date: Date): string {
	return new Intl.DateTimeFormat("cs", { year: 'numeric', month: 'long' }).format(date);
}

export function getFullDesc(date: Date): string {
	return new Intl.DateTimeFormat("cs", { year: 'numeric', month: 'long', day: 'numeric' }).format(date);
}

export function loadImg(src: string): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const image = new Image();

		image.addEventListener("load", () => resolve(image));
		image.addEventListener("error", () => reject(image));
		image.src = src;
	});
}

export function updateUrl(urlParams: { [key: string]: string }, replace?: false) {
	const url = new URL(window.location.origin);

	Object.keys(urlParams).forEach(param => {
		url.searchParams.set(param, urlParams[param]);
	});

	if (replace) {
		window.history.replaceState({}, '', url);
	} else {
		window.history.pushState({}, '', url);
	}
}

export function updateUrlFromData(position: IPosition, lang: string) {
	updateUrl({
		x: position.x.toFixed(WGS84_RANGE),
		y: position.y.toFixed(WGS84_RANGE),
		z: position.z.toString(),
		...lang === CS_LANG ? {} : { lang },
	});
}

export function trans(key: string, arg?: any): string {
	return key in window.i18nData ? window.i18nData[key](arg) : key;
}

/**
 * Combines strings and/or objects into one single string usable as a value for the className property
 */
export const classNames = (classes: Array<string | { [key: string]: boolean }> = []): string => (Array.isArray(classes)
	? classes
	: [classes]).reduce((allClasses, className) => {
	// an empty string will still pass - it is faster and breaks nothing
	if (typeof className === 'string') {
		allClasses.push(className);
	} else {
		// every truthy property is included in the final className
		className && Object.keys(className).forEach(key => className[key] && allClasses.push(key));
	}

	return allClasses;
}, <Array<string>>[]).join(' ');

export function getNearestLegendZoom(zoom: number): number {
	return HAPTIC_TILE_ZOOMS.reduce((prev, curr) => prev < zoom && zoom <= curr ? curr : prev);
}

export function getLegendForZoom(zoom: number): Array<{ item: string; icon: string; }> {
	const nearestZoom = getNearestLegendZoom(zoom);
	const legendWithZoom = `legend${nearestZoom}`;
	const filtered = Object.keys(window.i18nData).filter(item => item.indexOf(legendWithZoom) !== -1);

	return filtered.map(item => ({
		item,
		icon: item.replace(legendWithZoom, ""),
	}));
}

export function getVisibleContent(app: IApp): number {
	let visibleContent = VISIBLE_CONTENT.PROMO;

	if (app.showLegend) {
		visibleContent = VISIBLE_CONTENT.LEGEND;
	} else if (app.showThankYou) {
		visibleContent = VISIBLE_CONTENT.THANK_YOU;
	} else if (app.userFormState !== USER_FORM_STATES.NONE) {
		visibleContent = VISIBLE_CONTENT.USER_FORM;
	} else if (app.searchQuery) {
		visibleContent = VISIBLE_CONTENT.SEARCH_RESULTS;
	} else if (app.selectedTiles.length) {
		visibleContent = VISIBLE_CONTENT.PRINT;
	}

	return visibleContent;
}

export function getId(): number {
	return Math.random() * 1000000 >>> 0;
}

export function startBlobDownload(blob, filename) {
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement("a");

	link.href = url;
	link.download = filename;
	link.style.display = "none";
	document.body.appendChild(link);
	link.click();
	// kvuli FF je pry treba s odstranenim pockat, tak nejaky cas pockame
	setTimeout(() => {
		window.URL.revokeObjectURL(url);
		document.body.removeChild(link);
	}, 10000);
}

export function getMapyczLink(position: IPosition): string {
	const DEV_DOMAIN = ".dev.dszn.cz";
	const TEST_DOMAIN = ".test.dszn.cz";
	const usp = new URLSearchParams();

	usp.append("x", position.x.toFixed(WGS84_RANGE));
	usp.append("y", position.y.toFixed(WGS84_RANGE));
	usp.append("z", position.z.toString());

	if (location.href.indexOf(DEV_DOMAIN) >= 0) {
		return `https://mapy${DEV_DOMAIN}/?${usp.toString()}`;
	} else if (location.href.indexOf(TEST_DOMAIN) >= 0) {
		return `https://mapy${TEST_DOMAIN}/?${usp.toString()}`;
	}

	return `https://mapy.cz/?${usp.toString()}`;
}

export const debounce = <F extends ((...args: any) => any)>(func: F, waitFor: number) => {
	let timeout = null;

	const debounced = (...args: any) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(...args), waitFor);
	};

	return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

// jazyky: de, pl, sk jsou zatim neverejne
export function getLangs(curLang: string): Array<string> {
	const langs = [
		...ALL_LANGS,
	];

	// u cs, en prohodime
	if (langs.indexOf(curLang) !== -1) {
		langs.splice(langs.indexOf(curLang), 1);
		langs.unshift(curLang);
	}

	return langs;
}

export function splitToParts(inputValue: string): ISplitItem {
	const output: ISplitItem = [];
	let currentValue = "";
	let isTextMode = inputValue[0] !== "{";
	let isExprMode = inputValue[0] === "{";

	for (let ind = 0, max = inputValue.length; ind < max; ind++) {
		const curChar = inputValue[ind];

		if (isTextMode) {
			if (curChar !== "{") {
				currentValue += curChar;

				if (ind === max - 1) {
					output.push({
						value: currentValue,
						isText: true,
					});
				}
			} else if (curChar === "{") {
				isTextMode = false;
				isExprMode = true;
				output.push({
					value: currentValue,
					isText: true,
				});
				currentValue = "";
				continue;
			}
		} else if (isExprMode) {
			if (curChar === "}") {
				isTextMode = true;
				isExprMode = false;
				output.push({
					value: currentValue.trim(),
					isText: false,
				});
				currentValue = "";
				continue;
			} else if (curChar !== "{") {
				currentValue += curChar;
			}
		}
	}

	return output;
}

export function getAppLang(): string {
	const allLangs = [...ALL_LANGS];
	const usp = new URLSearchParams(location.search);
	const searchLang = usp.get("lang");
	let langInd = -1;

	// mame search parametr?
	if (searchLang) {
		langInd = allLangs.indexOf(searchLang);
	// nename
	} else {
		langInd = allLangs.indexOf(getCookie("lang"));
	}

	if (langInd === -1) {
		const isTactilesMap = location.href.indexOf("tactilemaps") !== -1;

		// tactiles map je anglicky + uk jako ukrajina
		return isTactilesMap || searchLang === "uk" ? EN_LANG : CS_LANG;
	}

	return allLangs[langInd];
}

export function setSeo() {
	document.title = trans("head_title");
	Array.from(document.querySelectorAll(`meta[property="og:title"]`)).forEach(elem => {
		elem.setAttribute("content", trans("head_title"));
	});
	Array.from(document.querySelectorAll(`meta[name="description"], meta[property="og:description"]`)).forEach(elem => {
		elem.setAttribute("content", trans("head_desc"));
	});
}

export function updateMapsets() {
	if (isLocalDev()) {
		const allKeys = Object.keys(window.SMap.CONFIG);
		const filtered = allKeys.filter(item => item.match(/\d+/u));

		for (const key of filtered) {
			const item = window.SMap.CONFIG[key];

			if (item.url) {
				item.url = item.url.replace("mapserver.mapy.cz", "mapserver.mapy.dev.dszn.cz");
			}

			if (item.retinaUrl) {
				item.retinaUrl = item.retinaUrl.replace("mapserver.mapy.cz", "mapserver.mapy.dev.dszn.cz");
			}
		}
	}
}
