import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ScrollToTop from "~/components/ScrollToTop";
import HomePage from "~/pages/HomePage";
import { appStore } from "~/stores/app";
import { getAppLang, setSeo, updateMapsets } from "~/utils/utils";

import './index.less';

async function main() {
	const container = document.getElementById('app') || document.body;
	const root = createRoot(container);
	const setLang = appStore.getState().setLang;
	const langCode = getAppLang();

	await Promise.all([
		import(/* webpackChunkName: "[request]" */ `../public/lang-${langCode}`),
		window.mapLoadProm,
	]);

	updateMapsets();
	setLang(langCode);
	setSeo();

	// render
	root.render(
		<Router>
			<ScrollToTop />
			<Routes>
				<Route path="*" element={<HomePage />} />
			</Routes>
		</Router>,
	);
}

main();
