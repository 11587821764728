/* eslint-disable no-magic-numbers */
import { IMapset, IFooterItem } from "~/interfaces";

export const ROUTES = {
	HOME: "/",
};

export const KEYS = {
	ESC: "Escape",
	LEFT: "ArrowLeft",
	RIGHT: "ArrowRight",
	UP: "ArrowUp",
	DOWN: "ArrowDown",
	ENTER: "Enter",
	SPACE: "Space",
	S: "KeyS",
};

export const MAPSETS: Array<IMapset> = [{
	active: false,
	isMapyCz: true,
	id: "zakladni",
	titleKey: "mapset_base",
	imgSrc: "/static/img/zakladni-small.png",
}, {
	active: true,
	isMapyCz: false,
	id: "haptic",
	titleKey: "mapset_haptic",
	imgSrc: "/static/img/hapticka-small.png",
}];

export const API_KEY = "gMElQhJ0OiRu0NkGdf-Pxrth0NdAQsWFuAvAiRwtuZ4";
export const CS_LANG = "cs";
export const EN_LANG = "en";
export const SK_LANG = "sk";
export const DE_LANG = "de";
export const PL_LANG = "pl";
export const ALL_LANGS = [CS_LANG, SK_LANG, EN_LANG, DE_LANG, PL_LANG];

export const DEF_POS = {
	x: 15.6252330,
	y: 49.8022514,
	z: 8,
};

export const PATHS = {
	SEARCH: "/search",
	LAYER: "https://mapserver.mapy.cz/haptic-m/",
	API: "/printHapLists",
	POIAGG: "/poiagg",
};

export const DEV_PATHS = {
	SEARCH: "/search",
	LAYER: "https://mapserver.mapy.dev.dszn.cz/haptic-m/",
	API: "https://hapticke.mapy.dev.dszn.cz/printHapLists",
	POIAGG: "https://api.mapy.cz/poiagg",
};

export const HAPTIC_TILE_ZOOMS = [11, 14, 19];
export const PRINT_SYNC_ZOOM = [0, 12, 15];

export const VISIBLE_CONTENT = {
	LEGEND: 1,
	USER_FORM: 2,
	SEARCH_RESULTS: 3,
	PRINT: 4,
	THANK_YOU: 5,
	PROMO: 6,
};

export const LIST_ID = {
	LEFT: 7,
	MIDDLE: 8,
	RIGHT: 9,
};

export const USER_FORM_STATES = {
	NONE: 0,
	ORDER: 1,
	HELP: 2,
};

export const DEFAULT_STATE_VALUE = {
	[CS_LANG]: "Česká republika",
	[SK_LANG]: "Slovensko",
	[EN_LANG]: "",
	[DE_LANG]: "Deutschland",
	[PL_LANG]: "Polska",
};

export const EMPTY_INFO = {
	listIds: [],
	shortcuts: false,
	maplegend: false,
	mapname: "",
	pois: false,
	pos: "",
};

export const WGS84_RANGE = 5;

export const SMALL_MEDIA_QUERY = "(max-width: 789px)";

// prevzate z mapy.cz
export const FLAGS_MAPPING = {
	[CS_LANG]: "cze",
	[SK_LANG]: "svk",
	[EN_LANG]: "gbr",
	[DE_LANG]: "deu",
	[PL_LANG]: "pol",
};

export const LANG_DATA = {
	LOGO_SRC: {
		[CS_LANG]: "/static/img/mainlogo-cs.svg",
		[SK_LANG]: "/static/img/mainlogo-sk.svg",
		[EN_LANG]: "/static/img/mainlogo-en.svg",
		[DE_LANG]: "/static/img/mainlogo-de.svg",
		[PL_LANG]: "/static/img/mainlogo-pl.svg",
	},
	GDPR_LINK: {
		[CS_LANG]: "https://o.seznam.cz/ochrana-udaju/",
		[SK_LANG]: "https://o.seznam.cz/ochrana-udaju/",
		[EN_LANG]: "https://o.seznam.cz/en/privacy-policy/",
		[DE_LANG]: "https://o.seznam.cz/en/privacy-policy/",
		[PL_LANG]: "https://o.seznam.cz/en/privacy-policy/",
	},
	SEZNAM_LINK: {
		[CS_LANG]: "https://o.seznam.cz/",
		[SK_LANG]: "https://o.seznam.cz/",
		[EN_LANG]: "https://o.seznam.cz/en/",
		[DE_LANG]: "https://o.seznam.cz/en/",
		[PL_LANG]: "https://o.seznam.cz/en/",
	},
	ELSA_LINK: {
		[CS_LANG]: "http://www.elsa.cvut.cz/",
		[SK_LANG]: "http://www.elsa.cvut.cz/",
		[EN_LANG]: "http://www.elsa.cvut.cz/index.php?menu=english",
		[DE_LANG]: "http://www.elsa.cvut.cz/index.php?menu=english",
		[PL_LANG]: "http://www.elsa.cvut.cz/index.php?menu=english",
	},
	TEIRESIAS_LINK: {
		[CS_LANG]: "https://www.teiresias.muni.cz/",
		[SK_LANG]: "https://www.teiresias.muni.cz/",
		[EN_LANG]: "https://www.teiresias.muni.cz/?lang=en-GB",
		[DE_LANG]: "https://www.teiresias.muni.cz/?lang=en-GB",
		[PL_LANG]: "https://www.teiresias.muni.cz/?lang=en-GB",
	},
	HELP_LINK: {
		[CS_LANG]: "https://napoveda.seznam.cz/cz/mapy/co-jsou-hapticke-mapy/",
		[SK_LANG]: "https://napoveda.seznam.cz/cz/mapy/co-jsou-hapticke-mapy/",
		[EN_LANG]: "https://napoveda.seznam.cz/en/what-are-tactile-maps/",
		[DE_LANG]: "https://napoveda.seznam.cz/en/what-are-tactile-maps/",
		[PL_LANG]: "https://napoveda.seznam.cz/en/what-are-tactile-maps/",
	},
	YOUTUBE_ID: {
		[CS_LANG]: "MlJWvsU_sT0",
		[SK_LANG]: "zl_uQFXl560",
		[EN_LANG]: "q1TMSEHeioY",
		[DE_LANG]: "bg8ot7yCoXk",
		[PL_LANG]: "IUjZUu5fKH8",
	},
};

export const FOOTER_LINKS: Array<IFooterItem> = [{
	title: "footer_seznam",
	hrefLangs: LANG_DATA.SEZNAM_LINK,
	isLegend: false,
}, {
	title: "footer_elsa",
	hrefLangs: LANG_DATA.ELSA_LINK,
	isLegend: false,
}, {
	title: "footer_teiresias",
	hrefLangs: LANG_DATA.TEIRESIAS_LINK,
	isLegend: false,
}, {
	title: "footer_help",
	hrefLangs: LANG_DATA.HELP_LINK,
	isLegend: false,
}, {
	title: "footer_legend",
	hrefLangs: null,
	isLegend: true,
}];

export const PROMOS_DATA = {
	ELSA2_EMAIL: "atelion@elsa.cvut.cz",
	TEIRESIAS2_EMAIL: "teiresias@muni.cz",
	BFMAPS: "www.bfmaps.org",
	BFMAPS_LINK: "http://www.bfmaps.org",
};

export const PROMOS = {
	PROMO1: {
		LITTLE: "little",
		MEDIUM: "medium",
		BIG: "big",
	},
	PROMO2: {
		ELSA2: "elsa2",
		TEIRESIAS2: "teiresias2",
	},
	PROMO3: {
		FORM: "form",
	},
	PROMO4: {
		ELSA2_EMAIL: "elsa2email",
	},
	PROMO5: {
		TEIRESIAS2_EMAIL: "teiresias2email",
	},
	PROMO6: {
		HELP: "help",
		PROJECT: "project",
	},
};

export const AREA_SOURCES = [
	"ward",
	"quar",
	"osmm",
	"muni",
	"dist",
	"area",
	"osma",
	"regi",
	"coun",
	"lvl2",
	"osms",
	"osml",
];

export const MIN_GRID_ZOOM = 8;

export const TILE_REGEX = /[a-z]\d{2,4}_[a-z]\d{2,4}/u;
