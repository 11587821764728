import { buildGeometry, computeZoom } from "~/utils/map";
import { isLocalDev } from "~/utils/utils";
import { PATHS, DEV_PATHS, AREA_SOURCES } from "~/const";

// pouze pro poie, ktere nemaji geom
const DESCRIPTION_CACHE = {};

function getIcon(poiTypeId: number): string {
	const iconData = window.SMap.POIServer.types[poiTypeId];

	if (iconData) {
		return iconData.url;
	}

	return "";
}

function description(pois: Array<{ source: string; id: number}>, lang: string): Promise<{
	poi: Array<object>;
	status: number;
}> {
	return new Promise(resolve => {
		const { JAK } = window;
		// [source]: [ids...]
		const poisConfig = {};

		pois.forEach(item => {
			if (!(item.source in poisConfig)) {
				poisConfig[item.source] = [];
			}

			poisConfig[item.source].push(item.id);
		});

		new JAK.RPC(JAK.RPC.AUTO, { endpoint: isLocalDev() ? DEV_PATHS.POIAGG : PATHS.POIAGG })
			.send("description", [poisConfig, {
				lang: [lang],
			}])
			.then(data => {
				resolve(data.data);
			}, () => resolve({
				status: 200,
				poi: [],
			}));
	});
}

interface IDetailData {
	isGeom: boolean;
	x: number;
	y: number;
	z: number;
	geometries: Array<any>;
}

function processDetailData(poi?: any, map?: any): IDetailData {
	const output = {
		isGeom: false,
		x: 0,
		y: 0,
		z: 0,
		geometries: [],
	};

	if (poi) {
		if (poi.geom) {
			const geomData = buildGeometry(poi.geom);
			const posData = map.computeCenterZoom(geomData.coords);
			const wgs84 = posData[0].toWGS84();

			output.isGeom = true;
			output.geometries = geomData.geometries;
			output.x = wgs84[0];
			output.y = wgs84[1];
			output.z = posData[1];
		} else {
			output.x = poi.mark.lon;
			output.y = poi.mark.lat;
			output.z = computeZoom(poi);
		}
	}

	return output;
}

export function getDetailData(source: string, id: number, map: any): Promise<IDetailData> {
	return new Promise(resolve => {
		const { JAK } = window;

		// z kese
		if (source in DESCRIPTION_CACHE && DESCRIPTION_CACHE[source][id]) {
			resolve(processDetailData(DESCRIPTION_CACHE[source][id], map));
		} else {
			// request
			new JAK.RPC(JAK.RPC.AUTO, { endpoint: isLocalDev() ? DEV_PATHS.POIAGG : PATHS.POIAGG })
				.send("detail", [source, id, {}])
				.then(data => {
					resolve(processDetailData(data?.data?.poi, map));
				}, () => resolve(processDetailData()));
		}
	});
}

export function getSearchFromQuery(query: string, lang: string, map: any): Promise<any> {
	return new Promise((resolve, reject) => {
		const { JAK } = window;
		const viewport = map.getViewport();
		const hints = {
			"3.fromLongitude": "float",
			"3.toLongitude": "float",
			"3.fromLatitude": "float",
			"3.toLatitude": "float",
		};
		/* eslint-disable no-magic-numbers */
		const params = [query, 0, 15, {
			title: true,
			zoom: map.getZoom(),
			fromLongitude: viewport.lbx,
			toLongitude: viewport.rtx,
			fromLatitude: viewport.lby,
			toLatitude: viewport.rty,
			lang: [lang],
		}];

		new JAK.RPC(JAK.RPC.AUTO, { endpoint: isLocalDev() ? DEV_PATHS.SEARCH : PATHS.SEARCH }).send("search", params, hints).then(data => {
			if (data.status >= 200 && data.status < 300) {
				const pois = data.data.result.map(item => ({
					source: item.source,
					id: item.id,
					iconData: getIcon(item.poiTypeId),
				}));

				description(pois, lang).then(descData => {
					if (descData.status >= 200 && descData.status < 300) {
						pois.forEach(poi => {
							const filtered = descData.poi.filter(poiItem => poiItem.id === poi.id && poiItem.source === poi.source);

							if (filtered.length) {
								Object.assign(poi, filtered[0]);

								if (poi.icon && poi.icon["1"]) {
									poi.iconData = poi.icon["1"];
								}
							}

							// ulozime do kese
							if (AREA_SOURCES.indexOf(poi.source) === -1) {
								if (!(poi.source in DESCRIPTION_CACHE)) {
									DESCRIPTION_CACHE[poi.source] = {};
								}

								DESCRIPTION_CACHE[poi.source][poi.id] = {
									mark: poi.mark,
									typeId: poi.typeId,
								};
							}
						});

						resolve(pois.filter(poi => !!poi.titleVars));
					} else {
						reject({});
					}
				}, () => reject({}));
			} else {
				reject({});
			}
		}, () => {
			reject({});
		});
	});
}
